<template>
    <el-option v-show="visible" :disabled="!visible" :value="value" :label="label">
        <slot></slot>
    </el-option>
</template>

<script>
export default {
    name: "SearchOption",
    components: {
    },
    props: {
        value: {
            type: [String, Object],
            default: null,
        },
        label: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            visible: true,
        };
    },
    methods: {
        setVisible(val) {
            this.visible = val;
        },
    },
    mounted() {

    }
};
</script>
  
<style scoped></style>
  