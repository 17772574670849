<template>
  <div>
    <van-sticky>
      <title-bar2 title="报录数据" />
    </van-sticky>
    <div class="guide-box">
      <span class="num-box">
        <span class="num1">1</span>
        <span class="num2">/</span>
        <span class="num2">2</span>
      </span>
      <span class="txt">请输入院校及专业信息？</span>
      <span class="upload-count">今日新增：{{ uploadCountOfToday }}个数据</span>
    </div>
    <div class="admit-divider"></div>
    <div class="card-box">
      <admit-data-filter :filter1.sync="filter1" :filter2.sync="filter2" :filter3.sync="filter3" :filter4.sync="filter4" @filter="onFilter"></admit-data-filter>
    </div>
    <div class="admit-divider"></div>
    <div class="admit-between">
      <div class="guide-box">
        <span class="num-box">
          <span class="num1">2</span>
          <span class="num2">/</span>
          <span class="num2">2</span>
        </span>
        <span class="txt">查询结果（{{ countAll }}）</span>
      </div>
    </div>
    <van-list class="admit-list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <admit-data-item class="admit-item" v-for="(admit, index) in admitInfos" :key="index" :unlocked="isMembership" :universityName="admit.UniversityName" :universityCode="admit.UniversityCode" :college="admit.CollegeName" :code="admit.MajorCode" :major="admit.MajorName" :province="admit.UniversityProvince" :partition="admit.UniversityPartition" :year="admit.MajorEnrollmentYear" :learnway="admit.MajorLearningWay" :admitInfoList="admit.AdmitInfos" @item-click="onItemClick(admit)" @unlock-click="onUnlockClick(admit)"></admit-data-item>
    </van-list>
  </div>
</template>

<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import StudentDialog1 from "@/components/MT/StudentDialog1";
import AdmitDataFilter from "@/components/MT/AdmitDataFilter";
import AdmitDataItem from "@/components/MT/AdmitDataItem";
import Config from "@/api/service";
import Tools from "@/tool";
import Manager from "@/manager";

export default {
  name: "MTModule",
  components: {
    TitleBar2,
    StudentDialog1,
    AdmitDataFilter,
    AdmitDataItem,
  },
  data() {
    return {
      studentDialog: false,
      active: 0,
      index: 0,
      total: 0,
      uploadCountOfToday: 0,
      countAll: 0,
      loading: false,
      finished: false,
      filter1: '',
      filter2: '',
      filter3: '',
      filter4: '全日制',
      isMembership: false,
      admitInfos: [],
    };
  },
  methods: {
    //获取路由参数
    getRoutParams() {

    },
    //获取用户是否是会员
    getIsMembership() {
      if (!this.$store.getters.isLogin) {
        return
      }
      var model = {};
      this.$axios
        .post(Config.member.purchased, model)
        .then((res) => {
          this.isMembership = res.data.Data.IsPurchased;
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //获取今日上传报录数量
    getAdmitStatistic() {
      var model = {};
      this.$axios
        .post(Config.admit.statistic, model)
        .then((res) => {
          this.uploadCountOfToday = res.data.Data.UploadCountOfToday;
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //获取报录数量
    getAdmitCount() {
      var model = {
        universityCode: this.filter1,
        CollegeCode: this.filter2,
        MajorCode: this.filter3,
        MajorLearningWay: this.filter4,
      };
      return this.$axios
        .post(Config.admit.majorCount, model)
        .then((res) => {
          let data = res.data.Data;
          this.countAll = data.CountAll;
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //获取报录列表
    getAdmitInfos() {
      var model = {
        universityCode: this.filter1,
        CollegeCode: this.filter2,
        MajorCode: this.filter3,
        MajorLearningWay: this.filter4,
        PageIndex: ++this.index,
        PageSize: 20,
      };
      this.$axios
        .post(Config.admit.majorList, model)
        .then((res) => {
          let data = res.data.Data;
          this.loading = false;
          this.total = data.TotalCount;
          this.admitInfos.push(...data.List);
          if (this.index >= data.PageCount) {
            this.finished = true;
          }
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //添加访问商品记录
    addVisitProductRecord() {
      Manager.addVisitProductRecord("报录数据");
    },
    //过滤改变
    onFilter() {
      this.onReload();
    },
    //加载列表
    onLoad() {
      this.getAdmitInfos();
    },
    //重新加载列表
    onReload() {
      this.finished = false;
      this.loading = true;
      this.index = 0;
      this.admitInfos = [];
      this.getAdmitCount();
      this.getAdmitInfos();
    },
    //选项卡切换
    onChange() {
      if (this.active < 3) {
        this.onReload();
      }
    },
    //点击详情项
    onItemClick(admit) {
      this.$router.push({
        name: "Admit1",
        params: {
          proxyCode: this.$store.state.proxyCode,
          universityCode: admit.UniversityCode,
          collegeCode: admit.CollegeCode,
          majorCode: admit.MajorCode,
          majorLearnway: admit.MajorLearningWay
        }
      });
    },
    //点击解锁
    onUnlockClick(admit) {
      this.$router.push({
        name: "SubmitMemberOrder",
        params: {
          proxyCode: this.$store.state.proxyCode
        }
      });
    },
  },
  mounted() {
    this.getRoutParams();
    this.getIsMembership();
    this.getAdmitStatistic();
    this.getAdmitCount();
    this.addVisitProductRecord();
  }
};
</script>
<style scoped lang="less">
.admit-list {
  margin-top: 10px;
}

.admit-item {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.admit-divider {
  margin: 20px 0;
}

.admit-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.guide-box {
  display: flex;
  align-items: center;
  background-color: #f6f6f6;
  padding-left: 10px;
  border-width: 0px;
  font-size: 14px;
  font-weight: 600;

  .num-box {
    display: inline;
    letter-spacing: 5px;

    .num1 {
      color: #ff6602;
      font-size: 18px;
      font-weight: 700;
    }

    .num2 {
      color: gray;
      font-size: 14px;
    }
  }

  .txt {
    margin-left: 10px;
  }

  .upload-count {
    color: #ff6602;
    font-size: 14px;
    margin-left: auto;
    margin-right: 10px;
  }
}

.card-box {
  background-color: white;
  margin: 10px;
  padding: 5px;
  border-radius: 10px;
}
</style>