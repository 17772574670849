<template>
    <div :class="selected ? 'radio-item radio-item_checked' : 'radio-item'" @click="onClick">{{ label }}</div>
</template>
  
<script>
export default {
    name: "RadioItem",
    components: {
    },
    inject: {
        RadioItemGroup: {
            default: null
        }
    },
    computed: {
        value: {
            get() {
                //获取时触发
                if (this.RadioItemGroup) {
                    return this.RadioItemGroup.value;
                }
            },
            set(val) {
                //赋值时触发
                if (this.RadioItemGroup) {
                    this.RadioItemGroup.updateValue(val);
                }
            }
        },
        selected: {
            get() {
                if (this.RadioItemGroup) {
                    return this.value == this.name;
                } else {
                    return this.checked;
                }
            },
        }
    },
    props: {
        checked: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
        };
    },
    methods: {
        onClick() {
            if (this.RadioItemGroup) {
                this.RadioItemGroup.updateValue(this.name);
            } else {
                this.$emit("update:checked", this.checked);
            }
            this.$emit('click');
        }
    },
    mounted() {

    }
};
</script>
  
<style scoped>
.radio-item {
    cursor: pointer;
    display: inline-block;
    white-space: pre-wrap;
    color: #323233;
    font-size: 14px;
    padding: 10px 10px;
}

.radio-item_checked {
    color: #FF6602;
}
</style>
  