<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="6">
        <search-select v-model="universityItem" value-key="Key" placeholder="学校" @change="onSelectChange1">
          <search-option v-for="item in universityInfos" :value="item" :label="item.UniversityName"></search-option>
        </search-select>
      </el-col>
      <el-col :span="6">
        <search-select v-model="collegeItem" value-key="Key" placeholder="学院" @change="onSelectChange2">
          <search-option v-for="item in collegeInfos" :value="item" :label="item.CollegeCode + item.CollegeName"></search-option>
        </search-select>
      </el-col>
      <el-col :span="6">
        <search-select v-model="majorItem" value-key="Key" placeholder="专业" @change="onSelectChange3">
          <search-option v-for="item in majorInfos" :value="item" :label="item.MajorCode + item.MajorName">
            <template>
              {{ `${item.MajorCode}${item.MajorName}/${item.CollegeCode}${item.CollegeName}` }}
            </template>
          </search-option>
        </search-select>
      </el-col>
      <el-col :span="6">
        <el-select v-model="value4" placeholder="学习方式" size="small" filterable @change="onSelectChange4">
          <el-option label="全日制" value="全日制"></el-option>
          <el-option label="非全日制" value="非全日制"></el-option>
        </el-select>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import RadioItem from "@/components/PC/RadioItem";
import RadioItemGroup from "@/components/PC/RadioItemGroup";
import CheckItem from "@/components/PC/CheckItem";
import CheckItemGroup from "@/components/PC/CheckItemGroup";
import SearchSelect from "@/components/PC/SearchSelect";
import SearchOption from "@/components/PC/SearchOption";
import Config from "@/api/service";
export default {
  name: "AdmitDataFilter",
  components: {
    RadioItem,
    RadioItemGroup,
    CheckItem,
    CheckItemGroup,
    SearchSelect,
    SearchOption,
  },
  props: {
    filter1: {
      type: String,
      default: '',
    },
    filter2: {
      type: String,
      default: '',
    },
    filter3: {
      type: String,
      default: '',
    },
    filter4: {
      type: String,
      default: '',
    },
  },
  computed: {
    universityItem: {
      get: function () {
        return this.universityInfos.find(item => item.UniversityCode == this.value1)
      },
      set: function (item) {
        this.value1 = item.UniversityCode;
      }
    },
    collegeItem: {
      get: function () {
        return this.collegeInfos.find(item => item.UniversityCode == this.value1 && item.CollegeCode == this.value2)
      },
      set: function (item) {
        this.value2 = item.CollegeCode;
      }
    },
    majorItem: {
      get: function () {
        return this.majorInfos.find(item => item.UniversityCode == this.value1 && item.CollegeCode == this.value2 && item.MajorCode == this.value3)
      },
      set: function (item) {
        this.value2 = item.CollegeCode;
        this.value3 = item.MajorCode;
      }
    },
    value1: {
      get: function () {
        return this.filter1;
      },
      set: function (val) {
        this.$emit('update:filter1', val);
      }
    },
    value2: {
      get: function () {
        return this.filter2;
      },
      set: function (val) {
        this.$emit('update:filter2', val);
      }
    },
    value3: {
      get: function () {
        return this.filter3;
      },
      set: function (val) {
        this.$emit('update:filter3', val);
      }
    },
    value4: {
      get: function () {
        return this.filter4;
      },
      set: function (val) {
        this.$emit('update:filter4', val);
      }
    },
  },
  data() {
    return {
      universityInfos: [],
      collegeInfos: [],
      majorInfos: [],
    };
  },
  methods: {
    //获取所有大学列表
    getAllUniversityList() {
      setTimeout(() => {
        var model = {};
        this.$axios
          .post(Config.admit.filter.schoolList, model)
          .then((res) => {
            //列表
            this.universityInfos = res.data.Data.UniversityInfos;
            //设置Key
            this.universityInfos.forEach(item => {
              item.Key = item.UniversityCode;
            });
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }, 0)
    },
    //获取所有学院列表
    getAllCollegeList() {
      setTimeout(() => {
        var model = {
          UniversityCode: this.value1
        };
        this.$axios
          .post(Config.admit.filter.collegeList, model)
          .then((res) => {
            //列表
            this.collegeInfos = res.data.Data.CollegeInfos;
            //设置Key
            this.collegeInfos.forEach(item => {
              item.Key = item.UniversityCode + item.CollegeCode;
            });
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }, 0)
    },
    //获取所有专业列表
    getAllMajorList() {
      setTimeout(() => {
        var model = {
          UniversityCode: this.value1,
          CollegeCode: this.value2
        };
        this.$axios
          .post(Config.admit.filter.majorList, model)
          .then((res) => {
            //列表
            this.majorInfos = res.data.Data.MajorInfos;
            //设置Key
            this.majorInfos.forEach(item => {
              item.Key = item.UniversityCode + item.CollegeCode + item.MajorCode;
            });
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }, 0)
    },
    //选择大学改变
    onSelectChange1() {
      this.value2 = null;
      this.value3 = null;
      this.collegeInfos = [];
      this.majorInfos = [];
      this.getAllCollegeList();
      this.getAllMajorList();
      this.$emit('filter');
    },
    //选择学院改变
    onSelectChange2() {
      this.value3 = null;
      this.majorInfos = [];
      this.getAllMajorList();
      this.$emit('filter');
    },
    //选择专业改变
    onSelectChange3() {
      this.$emit('filter');
    },
    //选择学习方式改变
    onSelectChange4() {
      this.$emit('filter');
    },
  },
  mounted() {
    this.getAllUniversityList();
  }
};
</script>

<style lang="less" scoped>
.box {
  font-size: 14px;
}

.img {
  width: 15px;
  height: 15px;
  padding: 10px 0;
  margin-top: 2px;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.center {
  align-items: center;
}

.fill {
  flex: 1;
}

.text1 {
  padding: 10px;
  color: black;
  word-spacing: 24px;
}

.text2 {
  width: 30px;
  padding: 10px;
  color: gray;
}

.search {
  width: 300px;
  margin-left: 10px;
  margin-top: 5px;
}

.select {
  margin-left: 10px;
}

.margin-column {
  margin: 20px 0;
}

.select-item {
  display: inline-block;
  white-space: pre-wrap;
  color: #FF6602;
  font-size: 14px;
  padding: 10px 10px;
}

::v-deep .el-collapse-item__header {
  min-height: 60px;
  height: unset;
  line-height: unset;
}
</style>
