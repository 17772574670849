<template>
    <div :class="selected ? 'radio-item radio-item_checked' : 'radio-item'" @click.stop="onClick">{{ label }}</div>
</template>
  
<script>

export default {
    name: "CheckItem",
    components: {
    },
    inject: {
        CheckItemGroup: {
            default: null
        }
    },
    computed: {
        selected: {
            get() {
                return this.CheckItemGroup.value.indexOf(this.name) > -1;
            },
        }
    },
    props: {
        icon: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
        };
    },
    methods: {
        onClick() {
            this.CheckItemGroup.updateValue(this.name, !this.selected);
            this.$emit('click');
        }
    },
    mounted() {

    }
};
</script>
  
<style scoped>
.radio-item {
    cursor: pointer;
    display: inline-block;
    white-space: pre-wrap;
    color: #323233;
    font-size: 14px;
    padding: 10px 10px;
}

.radio-item_checked {
    color: #FF6602;
}
</style>
  