<template>
  <div>
    <van-cell-group>
      <radio-cell-group v-model="universityItem" title="学校" placeholder="请选择学校" @change="onChange1">
        <radio-cell v-for="item in list1" :name="item" :label="item.UniversityName"></radio-cell>
      </radio-cell-group>
      <radio-cell-group v-model="collegeItem" title="学院" placeholder="请选择学院" @change="onChange2">
        <radio-cell v-for="item in list2" :name="item" :label="item.CollegeCode + item.CollegeName"></radio-cell>
      </radio-cell-group>
      <radio-cell-group v-model="majorItem" title="专业" placeholder="请选择专业" @change="onChange3">
        <radio-cell v-for="item in list3" :name="item" :label="`${item.MajorCode}${item.MajorName}/${item.CollegeCode}${item.CollegeName}`"></radio-cell>
      </radio-cell-group>
      <radio-cell-group v-model="value4" title="学习方式" placeholder="请选择学习方式" @change="onChange4">
        <radio-cell name="全日制" label="全日制"></radio-cell>
        <radio-cell name="非全日制" label="非全日制"></radio-cell>
      </radio-cell-group>
    </van-cell-group>
  </div>
</template>

<script>
import RadioCell from "@/components/MT/RadioCell";
import RadioCellGroup from "@/components/MT/RadioCellGroup";
import Config from "@/api/service";
export default {
  name: "AdmitDataFilter",
  components: {
    RadioCell,
    RadioCellGroup,
  },
  props: {
    filter1: {
      type: String,
      default: '',
    },
    filter2: {
      type: String,
      default: '',
    },
    filter3: {
      type: String,
      default: '',
    },
    filter4: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      list1: [],
      list2: [],
      list3: [],
    };
  },
  computed: {
    universityItem: {
      get: function () {
        return this.list1.find(item => item.UniversityCode == this.value1)
      },
      set: function (item) {
        this.value1 = item.UniversityCode;
      }
    },
    collegeItem: {
      get: function () {
        return this.list2.find(item => item.UniversityCode == this.value1 && item.CollegeCode == this.value2)
      },
      set: function (item) {
        this.value2 = item.CollegeCode;
      }
    },
    majorItem: {
      get: function () {
        return this.list3.find(item => item.UniversityCode == this.value1 && item.CollegeCode == this.value2 && item.MajorCode == this.value3)
      },
      set: function (item) {
        this.value2 = item.CollegeCode;
        this.value3 = item.MajorCode;
      }
    },
    value1: {
      get: function () {
        return this.filter1;
      },
      set: function (val) {
        this.$emit('update:filter1', val);
      }
    },
    value2: {
      get: function () {
        return this.filter2;
      },
      set: function (val) {
        this.$emit('update:filter2', val);
      }
    },
    value3: {
      get: function () {
        return this.filter3;
      },
      set: function (val) {
        this.$emit('update:filter3', val);
      }
    },
    value4: {
      get: function () {
        return this.filter4;
      },
      set: function (val) {
        this.$emit('update:filter4', val);
      }
    },
  },
  methods: {
    //获取所有大学列表
    getAllUniversityList() {
      setTimeout(() => {
        var model = {};
        this.$axios
          .post(Config.admit.filter.schoolList, model)
          .then((res) => {
            this.list1 = res.data.Data.UniversityInfos;
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }, 0)
    },
    //获取所有学院列表
    getAllCollegeList() {
      setTimeout(() => {
        var model = {
          UniversityCode: this.value1
        };
        this.$axios
          .post(Config.admit.filter.collegeList, model)
          .then((res) => {
            this.list2 = res.data.Data.CollegeInfos;
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }, 0)
    },
    //获取所有专业列表
    getAllMajorList() {
      setTimeout(() => {
        var model = {
          UniversityCode: this.value1,
          CollegeCode: this.value2
        };
        this.$axios
          .post(Config.admit.filter.majorList, model)
          .then((res) => {
            this.list3 = res.data.Data.MajorInfos;
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }, 0)
    },
    //选择大学改变
    onChange1() {
      this.value2 = null;
      this.value3 = null;
      this.list2 = [];
      this.list3 = [];
      this.getAllCollegeList();
      this.getAllMajorList();
      this.$emit("filter");
    },
    //选择学院改变
    onChange2() {
      this.value3 = null;
      this.list3 = [];
      this.getAllMajorList();
      this.$emit("filter");
    },
    //选择专业改变
    onChange3() {
      this.$emit("filter");
    },
    //选择专业学习方式改变
    onChange4() {
      this.$emit("filter");
    },
  },
  mounted() {
    this.getAllUniversityList();
  }
};
</script>

<style scoped lang="less">
.popup {
  height: 80%;
}

.popup-content {
  margin: 0px 10px 10px 10px;
}
</style>
