<template>
    <el-input :placeholder="placeholder" v-model="$data.dataValue" class="search-border" @keyup.enter.native="onSearchClick">
        <el-button slot="append" type="primary" class="search-button" @click="onSearchClick">搜索</el-button>
    </el-input>
</template>
  
<script>
export default {
    name: "SearchControl",
    components: {
    },
    props: {
        placeholder: {
            type: String,
            default: '请输入搜索内容',
        },
        value: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            dataValue: null,
        };
    },
    watch: {
        '$props.value': {
            deep: true,
            immediate: true,
            handler(val) {
                this.$data.dataValue = val;
            }
        },
        '$data.dataValue': {
            deep: true,
            immediate: true,
            handler(val) {
                this.$emit('update:value', val);
            }
        },
    },
    methods: {
        onSearchClick() {
            this.$emit("search-click");
        },
    },
};
</script>
  
<style scoped>
::v-deep .el-input-group__append {
    border-width: 0px;
    border-radius: 0px 5px 5px 0px;
    background-color: #FE5E03;
    color: white;
}

::v-deep .el-input-group__append:hover {
    background-color: rgb(252, 134, 65);
    color: white;
}

::v-deep .el-input-group__append:active {
    background-color: #FE5E03;
    color: white;
}
</style>
  