<template>
    <el-select ref="select" size="small" v-model="selectedValue" :value-key="valueKey" :placeholder="placeholder" filterable :filter-method="onFilter" @visible-change="onDropdown" @change="onChange">
        <slot></slot>
    </el-select>
</template>

<script>
export default {
    name: "SearchSelect",
    components: {
    },
    model: {
        prop: 'value',
        event: 'update:value'
    },
    computed: {
        selectedValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("update:value", val);
            }
        },
    },
    props: {
        value: {
            type: [String, Object],
            default: null,
        },
        placeholder: {
            type: String,
            default: '请输入',
        },
        valueKey: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
        };
    },
    methods: {
        onChange(val) {
            this.$emit("change", val);
        },
        onFilter(val) {
            if (this.$refs.select && this.$refs.select.$slots.default) {
                for (let i = 0; i < this.$refs.select.$slots.default.length; i++) {
                    let item = this.$refs.select.$slots.default[i].child;
                    //设置显隐
                    item.setVisible(item.label.includes(val));
                    //全匹配默认选中
                    if (item.label == val) {
                        this.selectedValue = item.value;
                        this.$emit("change", this.selectedValue);
                    }
                }
            }
        },
        onDropdown(vis) {
            if (vis) {
                if (this.$refs.select && this.$refs.select.$slots.default) {
                    for (let i = 0; i < this.$refs.select.$slots.default.length; i++) {
                        let item = this.$refs.select.$slots.default[i].child;
                        //设置显隐
                        if (item && item.setVisible) {
                            item.setVisible(true);
                        }
                    }
                }
            }
        },
    },
    mounted() {

    }
};
</script>

<style scoped></style>