<template>
  <div class="container-box">
    <div class="box-left">
      <div class="guide-box">
        <span class="numBox">
          <span class="num1">1</span>
          <span class="num2">/</span>
          <span class="num2">2</span>
        </span>
        <span class="txt">请输入院校及专业信息？</span>
        <span class="upload-count">今日新增：{{ uploadCountOfToday }}个专业数据</span>
      </div>
      <div class="dark-border school-filter">
        <admit-data-filter :filter1.sync="filter1" :filter2.sync="filter2" :filter3.sync="filter3" :filter4.sync="filter4" @filter="onFilterChange"></admit-data-filter>
      </div>
      <div class="guide-box">
        <span class="numBox">
          <span class="num1">2</span>
          <span class="num2">/</span>
          <span class="num2">2</span>
        </span>
        <span class="txt">查询结果</span>
      </div>
      <div class="tabs-box">
        <el-tabs class="school-tabs" type="card">
          <el-tab-pane :label="`全部（${countAll}）`"></el-tab-pane>
        </el-tabs>
        <div class="school-list" v-loading="loading" element-loading-text="加载中...">
          <div v-for="(admit, index) in admitInfos" :key="index">
            <admit-data-item :unlocked="isMembership" :universityName="admit.UniversityName" :universityCode="admit.UniversityCode" :college="admit.CollegeName" :major="admit.MajorName" :code="admit.MajorCode" :province="admit.UniversityProvince" :partition="admit.UniversityPartition" :year="admit.MajorEnrollmentYear" :learnway="admit.MajorLearningWay" :admitInfoList="admit.AdmitInfos" @item-click="onItemClick(admit)" @unlock-click="onUnlockClick(admit)"></admit-data-item>
            <div v-if="index < admitInfos.length - 1" class="bottom-line"></div>
          </div>
        </div>
      </div>
      <div class="pagination-box">
        <el-pagination background hide-on-single-page :current-page.sync="pageIndex" :page-size.sync="pageSize" :total.sync="totalCount" layout="total, prev, pager, next, jumper" @current-change="onCurrentChange"></el-pagination>
      </div>
    </div>
    <div class="box-right">
      <school-hot></school-hot>
    </div>
  </div>
</template>

<script>
import AdmitDataFilter from "@/components/PC/AdmitDataFilter";
import AdmitDataItem from "@/components/PC/AdmitDataItem";
import SchoolHot from "@/components/PC/SchoolHot";
import SearchControl from "@/components/PC/SearchControl";
import StudentDialog1 from "@/components/PC/StudentDialog1";
import Config from "@/api/service";
import Tools from "@/tool";
import Manager from "@/manager";

export default {
  name: "PCModule",
  components: {
    AdmitDataFilter,
    AdmitDataItem,
    SchoolHot,
    SearchControl,
    StudentDialog1,
  },
  data() {
    return {
      loading: true,
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      uploadCountOfToday: 0,
      countAll: 0,
      filter1: '',
      filter2: '',
      filter3: '',
      filter4: '全日制',
      admitInfos: [],
      isMembership: false,
    };
  },
  methods: {
    //获取路由参数
    getRoutParams() {

    },
    //获取用户是否是会员
    getIsMembership() {
      if (!this.$store.getters.isLogin) {
        return
      }
      var model = {};
      this.$axios
        .post(Config.member.purchased, model)
        .then((res) => {
          this.isMembership = res.data.Data.IsPurchased;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取今日上传报录数量
    getAdmitStatistic() {
      var model = {};
      this.$axios
        .post(Config.admit.statistic, model)
        .then((res) => {
          this.uploadCountOfToday = res.data.Data.UploadCountOfToday;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取报录数量
    getAdmitCount() {
      var model = {
        universityCode: this.filter1,
        CollegeCode: this.filter2,
        MajorCode: this.filter3,
        MajorLearningWay: this.filter4,
      };
      this.$axios
        .post(Config.admit.majorCount, model)
        .then((res) => {
          this.countAll = res.data.Data.CountAll;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取报录数据列表
    getAdmitInfos() {
      this.loading = true;
      var model = {
        universityCode: this.filter1,
        CollegeCode: this.filter2,
        MajorCode: this.filter3,
        MajorLearningWay: this.filter4,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };
      this.$axios
        .post(Config.admit.majorList, model)
        .then((res) => {
          let data = res.data.Data;
          this.totalCount = data.TotalCount;
          this.admitInfos = data.List;
          this.loading = false;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //添加访问商品记录
    addVisitProductRecord() {
      Manager.addVisitProductRecord("报录数据");
    },
    //过滤改变
    onFilterChange() {
      this.pageIndex = 1;
      this.getAdmitCount();
      this.getAdmitInfos();
    },
    //点击详情项
    onItemClick(admit) {
      this.$router.push({
        name: 'Admit1',
        params: {
          proxyCode: this.$store.state.proxyCode,
          universityCode: admit.UniversityCode,
          collegeCode: admit.CollegeCode,
          majorCode: admit.MajorCode,
          majorLearnway: admit.MajorLearningWay
        }
      })
    },
    //点击解锁
    onUnlockClick(admit) {
      this.$router.push({
        name: "SubmitMemberOrder",
        params: {
          proxyCode: this.$store.state.proxyCode
        }
      });
    },
    //点击分页
    onCurrentChange() {
      this.getAdmitInfos();
    },
  },
  mounted() {
    this.getRoutParams();
    this.getIsMembership();
    this.getAdmitStatistic();
    this.getAdmitCount();
    this.getAdmitInfos();
    this.addVisitProductRecord();
  }
};
</script>
<style scoped lang="less">
.container-box {
  display: flex;
  flex-direction: row;
}

.box-left {
  flex: 1;
  overflow: hidden;
}

.box-right {
  width: 300px;
  margin: 0 0 0 50px;
}

.tabs-box {
  position: relative;
}

.school-filter {
  padding: 20px;
}

.school-tabs {
  margin-top: 10px;
}

.school-list {
  min-height: 100px;
  padding: 20px;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}

.dark-border {
  border: 1px solid #e6e6e6;
}

.bottom-line {
  border-top: 1px solid #e6e6e6;
  margin: 10px 0;
}

.pagination-box {
  text-align: center;
  margin: 10px;
}

.guide-box {
  display: flex;
  align-items: center;
  margin: 20px 0;
  font-size: 18px;

  .numBox {
    display: inline;
    letter-spacing: 5px;

    .num1 {
      color: #ff6602;
      font-size: 20px;
      font-weight: 700;
    }

    .num2 {
      color: gray;
      font-size: 16px;
    }
  }

  .txt {
    margin-left: 10px;
  }

  .upload-count {
    color: #ff6602;
    font-size: 14px;
    margin-left: auto;
  }
}

::v-deep .el-tabs__header {
  margin: 0px;
}
</style>