<template>
    <div>
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: "CheckItemGroup",
    provide() {
        return {
            CheckItemGroup: this   //将组件本身的vue对象传递给下级
        };
    },
    model: {
        prop: 'value',
        event: 'update:value'
    },
    props: {
        value: {
            type: Array,
            default: []
        }
    },
    watch: {
        value: {
            immediate: false,
            handler(val) {
                this.$emit("change", val);
            }
        },
    },
    methods: {
        updateValue(val, checked) {
            if (checked) {
                if (this.value.indexOf(val) < 0) {
                    this.value.push(val);
                }
            } else {
                this.value.splice(this.value.indexOf(val), 1);
            }
            this.$emit("update:value", this.value);
        }
    },
};
</script>

<style>
.group {
    width: 100%;
}
</style>